<template>
    <div>
      <note-to-be-on-invoice-list></note-to-be-on-invoice-list>
    </div>
  </template>
  <script>
  export default {
  }
  </script>
  
  
  